import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap, filter, delay } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AuthActions from './auth.actions';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

import { ROUTER_NAVIGATION } from '@ngrx/router-store';

import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private _snackBar: MatSnackBar
  ) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      delay(500),
      concatMap((props) =>
        this.authService.login(props.email, props.password).pipe(
          map((data) => AuthActions.loginSuccess({ data })),
          catchError((error) => of(AuthActions.loginFailure({ error })))
        )
      )
    );
  });

  loginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap((res) => {
          this.router.navigate(['dashboard']);
          this._snackBar.open('login successfull!', 'close');
        })
      );
    },
    { dispatch: false }
  );

  loginFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginFailure),
        tap((res) => {
          this.router.navigate(['login']);
          this._snackBar.open(res.error.message, 'close');
        })
      );
    },
    { dispatch: false }
  );

  // LOGOUT
  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      tap((res) => {
        this.authService.logout();
        this.router.navigate(['login']);
        this._snackBar.open('Successfully logged out!', 'close');
      }),
      map(() => AuthActions.logoutConfirmed())
    );
  });

  //

  checkLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((action) => (action as any).payload.event.id === 1),
      map((data) =>
        AuthActions.setUserFromTokenSuccess({
          data: this.authService.getUser()
        })
      )
    );
  });

  activateAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.activateAccount),
      concatMap((props) =>
        this.authService
          .activateAccount({
            password: props.password,
            _token: props._token,
            password_confirmation: props.password_confirmation,
            user_id: props.user_id
          })
          .pipe(
            map((data) => AuthActions.activateAccountSuccess()),
            catchError((error) =>
              of(AuthActions.activateAccountFailure({ error }))
            )
          )
      )
    );
  });

  activateAccountSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.activateAccountSuccess),
        tap((res) => {
          this.router.navigate(['login']);
          this._snackBar.open('Successfully Activated', 'close');
        })
      );
    },
    { dispatch: false }
  );

  activateAccountFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.activateAccountFailure),
        tap((res) => {
          this._snackBar.open(res.error.message, 'close');
        })
      );
    },
    { dispatch: false }
  );

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      concatMap((props) =>
        this.authService
          .resetPassword({
            password: props.password,
            confirmPassword: props.confirmPassword,
            token: props.token
          })
          .pipe(
            map((data) => AuthActions.resetPasswordSuccess()),
            catchError((error) =>
              of(AuthActions.resetPasswordFailure({ error }))
            )
          )
      )
    );
  });

  resetPasswordSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.resetPasswordSuccess),
        tap((res) => {
          this.router.navigate(['login']);
          this._snackBar.open('Password Successfully Reseted', 'close');
        })
      );
    },
    { dispatch: false }
  );

  resetPasswordFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.resetPasswordFailure),
        tap((res) => {
          this._snackBar.open(res.error.message, 'close');
        })
      );
    },
    { dispatch: false }
  );
}
