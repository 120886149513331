<mat-sidenav-container
  *ngrxLet="user$; let user"
  [hasBackdrop]="false"
  class="example-container"
  autosize
>
  <mat-sidenav
    [autoFocus]="false"
    [class._expanded]="isExpanded || isShowing"
    #sidenav
    class="example-sidenav"
    [mode]="'over'"
    opened="true"
    (mouseenter)="mouseenter()"
    (mouseleave)="mouseleave()"
  >
    <ul class="left-menu">
      <li>
        <button mat-icon-button class="top-menu-btn">
          <mat-icon>menu</mat-icon>
        </button>
      </li>

      <ng-container *ngFor="let item of menuConfig; index as i">
        <ng-container *ngIf="item?.roles?.includes(user.role)">
          <li *ngIf="!item.hasChild; else hasSub">
            <a
              routerLinkActive="active"
              class="menu-parent-item"
              [routerLink]="item.path"
              href=""
            >
              <mat-icon
                class="material-icons-outlined"
                aria-hidden="false"
                aria-label="home"
              >
                {{ item.icon }}
              </mat-icon>
              <span>{{ item.title }}</span>
            </a>
          </li>

          <ng-template #hasSub>
            <mat-expansion-panel
              [expanded]="item.isActive && isShowing"
              #dropdown
            >
              <mat-expansion-panel-header
                [class.active]="item.isActive"
                [expandedHeight]="'48px'"
              >
                <mat-panel-title class="d-flex align-items-center">
                  <mat-icon
                    class="material-icons-outlined"
                    aria-hidden="false"
                    aria-label="home"
                  >
                    {{ item.icon }}
                  </mat-icon>
                  <span>{{ item.title }}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="submenu">
                <a
                  routerLinkActive="active-sub"
                  *ngFor="let subitem of item.children"
                  [routerLink]="subitem.path"
                  href=""
                >
                  <span>{{ subitem.title }}</span>
                </a>
              </div>
            </mat-expansion-panel>
          </ng-template>
        </ng-container>
      </ng-container>
    </ul>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary" class="example-toolbar">
      <div class="logo">
        <img src="assets/images/logo.png" alt="" />
      </div>
      <div class="search-info">
        <div class="search">
          <input
            (keydown)="search($event)"
            [formControl]="searchControl"
            placeholder="Search"
            type="text"
          />
          <mat-icon>search</mat-icon>
        </div>
        <p class="admin-email">{{ user?.email }}</p>
        <p class="role">{{ user?.role }}</p>
        <button (click)="logout()" class="logout-btn">Log out</button>
      </div>
    </mat-toolbar>
    <main class="container">
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
