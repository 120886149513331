import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';

import { environment } from '@env/environment';
import { DictionaryModel } from '@app/dictionary/models/dictionary.model';
import { LblCategoryEnum } from '@app/shared/enums/lbl-category.enum';

export class TranslateServerLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<any> {
    return new Observable((observer) => {
      fetch(`${environment.apiUrl}/frontend/dictionary`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Network response was not ok (${response.status})`);
          }

          return response.json();
        })
        .then(({ message }) =>
          message
            .map((item: any) => DictionaryModel.fromJson(item))
            .filter(
              (item: DictionaryModel) =>
                item.lblCategory === LblCategoryEnum.Cellar
            )
        )
        .then((data: DictionaryModel[]) => {
          const dictionary: { [key: string]: string } = {};
          data.forEach((item) => {
            dictionary[item.lblKey] = item[`value_${lang}`];
          });

          return dictionary;
        })
        .then((data) => {
          observer.next(data);
          observer.complete();
        });
    });
  }
}

export function translateServerLoaderFactory() {
  return new TranslateServerLoader();
}
