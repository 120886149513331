import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { delay, map, Observable, take, tap } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromAuth from '@app/auth/auth.selectors';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(fromAuth.selectUser).pipe(
      map((user) => {
        if (!user) {
          this.router.navigate(['login']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
