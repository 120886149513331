import { Role } from '@app/shared/models/role.model';

export type MenuItem = {
  title: string;
  hasChild: boolean;
  path: Array<string>;
  icon?: string;
  isExpanded?: boolean;
  isActive?: boolean;
  children?: Array<MenuItem>;
  roles: Role[];
};

export const menuConfig: MenuItem[] = [
  {
    path: ['dashboard'],
    title: 'Home',
    hasChild: false,
    icon: 'cottage_outline',
    isActive: false,
    roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
  },
  {
    path: ['who-we-are'],
    title: 'Who We Are Images',
    hasChild: false,
    icon: 'photo_camera_front',
    isActive: false,
    roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
  },
  {
    path: ['team'],
    title: 'Team',
    icon: 'diversity_2',
    hasChild: true,
    isExpanded: false,
    isActive: false,
    children: [
      {
        path: ['team/management'],
        title: 'Management',
        hasChild: false,
        roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
      },
      {
        path: ['team/positions'],
        title: 'Positions',
        hasChild: false,
        roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
      },
      {
        path: ['team/hashtags'],
        title: 'Hashtags',
        hasChild: false,
        roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
      }
    ],
    roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
  },
  {
    path: ['join-us'],
    title: 'Join Us',
    icon: 'work-outline',
    hasChild: true,
    isExpanded: false,
    children: [
      {
        path: ['join-us/management'],
        title: 'Management',
        hasChild: false,
        roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
      },
      {
        path: ['join-us/job-title'],
        title: 'Job Titles',
        hasChild: false,
        roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
      },
      {
        path: ['join-us/hashtags'],
        title: 'Job Hashtags',
        hasChild: false,
        roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
      },
      {
        path: ['join-us/experience'],
        title: 'Job Experience',
        hasChild: false,
        roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
      }
    ],
    roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
  },
  {
    path: ['blog'],
    title: 'Blog',
    icon: 'newspaper',
    hasChild: true,
    isExpanded: false,
    children: [
      {
        path: ['blog/management'],
        title: 'Management',
        hasChild: false,
        roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
      },
      {
        path: ['blog/hashtags'],
        title: 'Blog Hashtags',
        hasChild: false,
        roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
      }
    ],
    roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
  },
  {
    path: ['contact-requests'],
    title: 'Contact Requests',
    hasChild: true,
    icon: 'alternate_email',
    children: [
      {
        path: ['contact-requests/form'],
        title: 'Contact Form',
        hasChild: false,
        roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
      },
      {
        path: ['contact-requests/chat'],
        title: 'Chat Bot',
        hasChild: false,
        roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
      }
    ],
    roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
  },
  {
    path: ['cv-uploads'],
    title: 'CV Uploads',
    hasChild: false,
    icon: 'feed',
    roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
  },
  {
    path: ['user-management'],
    title: 'User management',
    hasChild: false,
    icon: 'supervised_user_circle',
    roles: [Role.ADMIN]
  },
  {
    path: ['dictionary'],
    title: 'dictionary',
    hasChild: false,
    icon: 'translate',
    roles: [Role.ADMIN, Role.MODERATOR, Role.MANAGER]
  },
  {
    path: ['languages'],
    title: 'languages',
    hasChild: false,
    icon: 'language',
    roles: [Role.ADMIN]
  }
];
