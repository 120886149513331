import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Account } from './account.model';
import { environment } from '@env/environment';

import { catchError, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Role } from '@app/shared/models/role.model';

const jwtHelperService = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly baseUrl = `${environment.apiUrl}`;
  public redirectUrl = '/';

  constructor(private httpClient: HttpClient) {}

  login(email: string, password: string): Observable<Account.Model> {
    const endpoint = `${this.baseUrl}/login`;
    const body = { email, password };

    return this.httpClient.post(endpoint, body).pipe(
      map((json: any) => {
        this.setToken(json.access_token);
        const account = jwtHelperService.decodeToken(
          json.access_token as string
        );
        return Account.fromJson(account);
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        let message: string;
        if (errorResponse.status === 403) {
          message = 'Invalid credentials provided';
        } else if (errorResponse.status === 401) {
          message = 'Wrong email or password';
        } else {
          message = 'Something went wrong, please try later';
        }

        return throwError(() => new Error(message));
      })
    );
  }

  getUserByToken(token: string) {
    const endpoint = `${this.baseUrl}/activate-account/${token}`;

    return this.httpClient.get(endpoint).pipe(map((res: any) => res.response));
  }

  activateAccount(data: {
    password: string;
    _token: string;
    password_confirmation: string;
    user_id: number;
  }) {
    const endpoint = `${this.baseUrl}/activate-account`;
    return this.httpClient.post(endpoint, data);
  }

  setToken(token: string): void {
    localStorage.setItem('usertoken', token);
  }

  logout() {
    localStorage.removeItem('usertoken');
  }

  getToken(): string | null {
    const userToken = localStorage.getItem('usertoken');
    try {
      if (userToken) {
        if (jwtHelperService.isTokenExpired(userToken)) {
          return null;
        }
      }

      return userToken;
    } catch (e) {
      return null;
    }
  }

  getUser(): Account.Model | null {
    const token = this.getToken();

    try {
      const decoded = jwtHelperService.decodeToken(token as string);
      return Account.fromJson(decoded);
    } catch (e) {
      return null;
    }
  }

  resetPassword(data: {
    password: string;
    confirmPassword: string;
    token: string;
  }) {
    const endpoint = `${this.baseUrl}/reset-password`;

    const body = {
      _token: data.token,
      password: data.password,
      password_confirmation: data.confirmPassword
    };

    return this.httpClient.post(endpoint, body);
  }

  // getTokenExpiration(): Date {
  //   const expiration = localStorage.getItem('usertoken_exp');

  //   return jwtHelperService.getTokenExpirationDate(JSON.parse(expiration));
  // }
}
