<div class="modal-header">
  <h1 mat-dialog-title>
    {{ dialogData.title }}
  </h1>
  <button (click)="onDismiss()" class="cancel">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <p [innerHtml]="dialogData.text"></p>
</div>

<div class="hr"></div>

<div mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="onConfirm()">
    {{ dialogData.submitBtnText || 'ok' }}
  </button>
  <button
    *ngIf="!dialogData.hideCancel"
    mat-flat-button
    color="warn"
    (click)="onDismiss()"
  >
    {{ dialogData.cancelBtnText || 'cancel' }}
  </button>
</div>
