import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  UrlTree
} from '@angular/router';
import { AuthService } from '@app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.hasExpected(route.data);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.hasExpected(childRoute.data);
  }

  canLoad(route: Route): boolean | UrlTree {
    return this.hasExpected(route.data);
  }

  private hasExpected(routeData): boolean | UrlTree {
    const expectedRoles: undefined | string[] = routeData.roles;

    let hasExpectedRole = true;

    if (expectedRoles) {
      hasExpectedRole = expectedRoles.includes(this.authService.getUser().role);
    }

    if (hasExpectedRole) {
      return true;
    }

    return this.router.createUrlTree(['dashboard']);
  }
}
