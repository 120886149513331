import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './auth.effects';
import { StoreModule } from '@ngrx/store';

import * as auth from './auth.reducer';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    // STORE
    StoreModule.forFeature(auth.authFeatureKey, auth.reducer),
    EffectsModule.forFeature([AuthEffects])
    // /
  ]
})
export class AuthModule {}
