import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreComponent } from './core/core.component';
import { CoreModule } from './core/core.module';
import { AuthGuard } from './auth/auth.guard';
import { Role } from '@app/shared/models/role.model';
import { RoleGuard } from '@app/auth/role.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/login/login.module').then((exports) => exports.LoginModule)
  },
  {
    path: 'account-activation',
    loadChildren: () =>
      import('./auth/account-activation/account-activation.module').then(
        (exports) => exports.AccountActivationModule
      )
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./auth/reset-password/reset-password.module').then(
        (exports) => exports.ResetPasswordModule
      )
  },
  {
    path: '',
    component: CoreComponent,
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(
            (exports) => exports.DashboardModule
          )
      },
      {
        path: 'who-we-are',
        loadChildren: () =>
          import('./who-we-are/who-we-are.module').then(
            (exports) => exports.WhoWeAreModule
          )
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./search/search.module').then(
            (exports) => exports.SearchModule
          )
      },
      {
        path: 'team',
        loadChildren: () =>
          import('./team/team.module').then((exports) => exports.TeamModule)
      },
      {
        path: 'dictionary',
        loadChildren: () =>
          import('./dictionary/dictionary.module').then(
            (exports) => exports.DictionaryModule
          )
      },
      {
        path: 'languages',
        data: {
          roles: [Role.ADMIN]
        },
        loadChildren: () =>
          import('./languages/languages.module').then(
            (exports) => exports.LanguagesModule
          )
      },
      {
        path: 'join-us',
        loadChildren: () =>
          import('./join-us/join-us.module').then(
            (exports) => exports.JoinUsModule
          )
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('./blog/blog.module').then((exports) => exports.BlogModule)
      },
      {
        path: 'contact-requests',
        loadChildren: () =>
          import('./contact-requests/contact-requests.module').then(
            (exports) => exports.ContactRequestsModule
          )
      },
      {
        path: 'cv-uploads',
        loadChildren: () =>
          import('./cv-uploads/cv-uploads.module').then(
            (exports) => exports.CvUploadsModule
          )
      },
      {
        path: 'user-management',
        data: {
          roles: [Role.ADMIN]
        },
        loadChildren: () =>
          import('./user-management/user-management.module').then(
            (exports) => exports.UserManagementModule
          )
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CoreModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
