import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { NavigationEnd, Router } from '@angular/router';
import { tap } from 'rxjs';
import { menuConfig } from './menu.config';

import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';

import * as authActions from '@app/auth/auth.actions';
import * as authSelectors from '@app/auth/auth.selectors';
import { languagePageActions } from '@app/languages/store';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class CoreComponent implements OnInit {
  @ViewChildren('dropdown') dropdownMenu: QueryList<MatExpansionPanel>;
  isExpanded = false;
  isShowing = false;

  menuConfig = menuConfig;

  currentRoute = '';
  searchControl = new FormControl();

  user$ = this.store.select(authSelectors.selectUser);

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
      this.dropdownMenu.forEach((el) => el.close());
    }
  }

  constructor(
    public router: Router,
    private dialog: MatDialog,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(languagePageActions.loadLanguages());
    this.handleRoutes(this.router.url);

    this.router.events
      .pipe(
        tap((r) => {
          if (r instanceof NavigationEnd) {
            this.handleRoutes(r.url);
            this.searchControl.reset();
          }
        })
      )
      .subscribe();
  }

  search(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.router.navigate(['search'], {
        queryParams: {
          search: this.searchControl.value
        }
      });
    }
  }

  handleRoutes(url: string) {
    this.currentRoute = url;

    this.menuConfig = this.menuConfig.map((item) => {
      if (url.includes(item.path[0])) {
        item.isActive = true;
        return { ...item, isActive: true };
      }
      return { ...item, isActive: false };
    });
  }

  logout() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'Logout',
          text: 'You are going to Log out from the system'
        },
        width: '760px'
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.store.dispatch(authActions.logout());
        }
      });
  }
}
