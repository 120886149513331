import { createAction, props } from '@ngrx/store';
import { LanguageManagement } from '@app/languages/models/languages-management.model';
import { HttpErrorResponse } from '@angular/common/http';

const actionsNamespace = '[Language]';

export const loadLanguages = createAction(`${actionsNamespace} Load Languages`);

export const loadLanguagesSuccess = createAction(
  `${actionsNamespace} Load Languages Success`,
  props<{ data: LanguageManagement.Language[] }>()
);

export const loadLanguagesFailure = createAction(
  `${actionsNamespace} Load Languages Failure`,
  props<{ error: HttpErrorResponse }>()
);

export const changePublishingStatus = createAction(
  `${actionsNamespace} Change Publishing Status`,
  props<{ language: LanguageManagement.Language }>()
);

export const changePublishingStatusSuccess = createAction(
  `${actionsNamespace} Change Publishing Status Success`,
  props<{ language: LanguageManagement.Language }>()
);

export const changePublishingStatusFailure = createAction(
  `${actionsNamespace} Change Publishing Status Failure`,
  props<{ error: HttpErrorResponse }>()
);

export const setDefault = createAction(
  `${actionsNamespace} Set Default`,
  props<{ language: LanguageManagement.Language }>()
);

export const setDefaultSuccess = createAction(
  `${actionsNamespace} Set Default Success`,
  props<{ language: LanguageManagement.Language }>()
);

export const setDefaultFailure = createAction(
  `${actionsNamespace} Set Default Failure`,
  props<{ error: HttpErrorResponse }>()
);
