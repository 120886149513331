import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as LanguagesActions from './languages.actions';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { LanguagesService } from '@app/_api/languages.service';
import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class LanguagesEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private languagesService: LanguagesService,
    private _snackBar: MatSnackBar
  ) {}

  loadLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguagesActions.loadLanguages),
      concatMap((props) =>
        this.languagesService.load().pipe(
          map((data) => LanguagesActions.loadLanguagesSuccess({ data })),
          catchError((error: HttpErrorResponse) => {
            this._snackBar.open(error.message, 'close');
            return of(LanguagesActions.loadLanguagesFailure({ error }));
          })
        )
      )
    )
  );

  changePublishingStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguagesActions.changePublishingStatus),
      concatMap(({ language }) =>
        this.languagesService.publish(language).pipe(
          map((data) =>
            LanguagesActions.changePublishingStatusSuccess({
              language
            })
          ),
          catchError((error: HttpErrorResponse) => {
            this._snackBar.open(error.message, 'close');
            return of(
              LanguagesActions.changePublishingStatusFailure({
                error
              })
            );
          })
        )
      )
    )
  );

  setDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguagesActions.setDefault),
      concatMap(({ language }) =>
        this.languagesService.setDefault(language).pipe(
          switchMap((data) => [
            LanguagesActions.setDefaultSuccess({ language }),
            LanguagesActions.loadLanguages()
          ]),
          catchError((error: HttpErrorResponse) => {
            this._snackBar.open(error.message, 'close');
            return of(LanguagesActions.setDefaultFailure({ error }));
          })
        )
      )
    )
  );
}
