export namespace LanguageManagement {
  export type Language = {
    id: number;
    iso: string;
    name: string;
    isActive: number;
    isDefault: number;
  };

  export function fromJson(json: any): Language {
    return {
      id: json.id,
      iso: json.iso,
      name: json.name,
      isActive: json.is_active,
      isDefault: json.is_default
    };
  }

  export function toJson(model: Language) {
    return {
      name: model.name
    };
  }
}
