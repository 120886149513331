import { createAction, props } from '@ngrx/store';
import { Account } from './account.model';

export const login = createAction(
  '[Auth] Login',
  props<{ email: string; password: string }>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ data: Account.Model }>()
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: any }>()
);

export const checkLogin = createAction('[Auth] Check Login');

export const logout = createAction('[Auth] Logout');

export const logoutConfirmed = createAction('[Auth] Logout Confirmed');

export const logoutCancelled = createAction('[Auth] Logout Cancelled');

export const activateAccount = createAction(
  '[Auth] Activate Account',
  props<{
    password: string;
    _token: string;
    password_confirmation: string;
    user_id: number;
  }>()
);

export const activateAccountSuccess = createAction(
  '[Auth] Activate Account Success'
);

export const activateAccountFailure = createAction(
  '[Auth] Activate Account Failure',
  props<{ error: any }>()
);

export const setUserFromTokenSuccess = createAction(
  '[Auth] Try Set User Success',
  props<{ data: Account.Model }>()
);

export const resetPassword = createAction(
  '[Auth] Reset Password',
  props<{
    password: string;
    confirmPassword: string;
    token: string;
  }>()
);

export const resetPasswordSuccess = createAction(
  '[Auth] Reset Password Success'
);

export const resetPasswordFailure = createAction(
  '[Auth] Reset Password Failure',
  props<{ error: any }>()
);
