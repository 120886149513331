import { Role } from '@app/shared/models/role.model';

export namespace Account {
  export interface Model {
    accountId: number;
    email: string;
    role: Role;
    roleId: number;
  }

  export function fromJson(json: any): Model {
    return {
      accountId: json.id,
      email: json.email,
      role: json.roleName,
      roleId: json.roleId
    };
  }
}
