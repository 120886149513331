import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageManagement } from '@app/languages/models/languages-management.model';

import { environment } from '@env/environment';
import { delay, map, mergeMap, Observable, of, throwError } from 'rxjs';
import { TeamManagement } from '@app/team/models/team-management.model';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  private readonly baseUrl = `${environment.apiUrl}/cellar/languages`;
  private readonly baseUrlChangeStatus = `${environment.apiUrl}/cellar/language-change-status`;
  private readonly baseUrlSetDefault = `${environment.apiUrl}/cellar/language-set-default`;

  constructor(private http: HttpClient) {}

  load(): Observable<LanguageManagement.Language[]> {
    return this.http
      .get(this.baseUrl)
      .pipe(
        map((res: any) =>
          res.message.map((item) => LanguageManagement.fromJson(item))
        )
      );
  }

  loadById(id: number): Observable<LanguageManagement.Language> {
    const endpoint = `${this.baseUrl}/${id}`;

    return this.http
      .get(endpoint)
      .pipe(map((res: any) => LanguageManagement.fromJson(res.message)));
  }

  create(hashtag: LanguageManagement.Language) {
    let endpoint = `${this.baseUrl}`;
    let json = LanguageManagement.toJson(hashtag);

    return this.http.post(endpoint, json);
  }

  update(hashtag: LanguageManagement.Language) {
    let endpoint = `${this.baseUrl}/${hashtag.id}`;
    let json = LanguageManagement.toJson(hashtag);

    return this.http.patch(endpoint, json);
  }

  delete(hashtagId: number) {
    let endpoint = `${this.baseUrl}/${hashtagId}`;
    return this.http.delete(endpoint);
  }

  publish(language: LanguageManagement.Language) {
    let endpoint = `${this.baseUrlChangeStatus}`;
    let json = {
      id: language.id,
      status: language.isActive
    };
    return this.http.post(endpoint, json);
  }
  setDefault(language: LanguageManagement.Language) {
    let endpoint = `${this.baseUrlSetDefault}`;
    let json = {
      id: language.id
    };
    return this.http.post(endpoint, json);
  }
}
