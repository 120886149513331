import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLanguages from './languageFeature';
import { languagesEntityAdapter } from './languageFeature';
import { LanguageManagement } from '@app/languages/models/languages-management.model';
import { LanguagesEntityState } from '@app/languages/models/languages-state.model';

export const selectLanguagesState = createFeatureSelector<LanguagesEntityState>(
  fromLanguages.languageFeatureKey
);

export const { selectAll } = languagesEntityAdapter.getSelectors();

export const selectLanguages = createSelector(selectLanguagesState, selectAll);

export const selectActiveLanguages = createSelector(
  selectLanguages,
  (languages: LanguageManagement.Language[]): LanguageManagement.Language[] =>
    languages.filter((item) => item.isActive)
);

export const selectLoading = createSelector(
  selectLanguagesState,
  (state: LanguagesEntityState) => state.loading
);
