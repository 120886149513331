import { LblCategoryEnum } from '@app/shared/enums/lbl-category.enum';
import { LanguageManagement } from '@app/languages/models/languages-management.model';
import { FormControl } from '@angular/forms';
import { DictionaryStore } from '@app/dictionary/dictionary.store';

export class DictionaryModel {
  id: number;
  lblKey: string;
  lblCategory: LblCategoryEnum;
  lblCategoryValue: string;
  valueAm: string;
  valueRu: string;
  valueEn: string;
  createdBy: string;
  updatedBy: number | null;
  createdAt: Date;
  updatedAt: Date;
  value_am: string;
  value_ru: string;
  value_en: string;

  static fromJson(json: any): DictionaryModel {
    return {
      id: json.id,
      lblKey: json.lbl_key,
      lblCategory: +json.lbl_category,
      lblCategoryValue: LblCategoryEnum[json.lbl_category],
      valueAm: json.value_am,
      valueRu: json.value_ru,
      valueEn: json.value_en,
      value_am: json.value_am,
      value_ru: json.value_ru,
      value_en: json.value_en,
      createdBy: json.created_by,
      updatedBy: json.updated_by,
      createdAt: json.created_at,
      updatedAt: json.updated_at
    };
  }
}

export type DictionaryManage = {
  id?: number;
  lbl_key: string;
  lbl_category: LblCategoryEnum;
  value_am: string;
  value_en: string;
};

export interface ManageDictionaryDialogData {
  dictionary?: DictionaryModel;
  languages: LanguageManagement.Language[];
  store: DictionaryStore;
}

export interface ManageDictionaryFormGroup {
  lbl_key: FormControl<string>;
  lbl_category: FormControl<LblCategoryEnum>;
  [key: string]: FormControl<string | LblCategoryEnum>;
}

export enum UploadDictionaryEnum {
  Import = 1,
  Export
}
