import { createFeature, createReducer, on } from '@ngrx/store';
import * as LanguagesActions from './languages.actions';
import { LanguagesEntityState } from '@app/languages/models/languages-state.model';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { LanguageManagement } from '@app/languages/models/languages-management.model';

export const languageFeatureKey: string = 'languages';

export const languagesEntityAdapter: EntityAdapter<LanguageManagement.Language> =
  createEntityAdapter<LanguageManagement.Language>({
    selectId: (entity) => entity.id,
    sortComparer: false
  });

const initialState: LanguagesEntityState =
  languagesEntityAdapter.getInitialState({
    loading: false,
    error: null
  });

export const languageFeature = createFeature({
  name: languageFeatureKey,
  reducer: createReducer(
    initialState,
    on(LanguagesActions.loadLanguages, (state) => ({
      ...state,
      loading: true,
      error: null
    })),
    on(LanguagesActions.loadLanguagesSuccess, (state, { data }) =>
      languagesEntityAdapter.setMany(data, {
        ...state,
        loading: false,
        error: null
      })
    ),
    on(LanguagesActions.loadLanguagesFailure, (state, { error }) => ({
      ...state,
      loading: false,
      error: error
    })),
    on(LanguagesActions.changePublishingStatus, (state) => ({
      ...state,
      loading: true,
      error: null
    })),
    on(LanguagesActions.changePublishingStatusSuccess, (state, { language }) =>
      languagesEntityAdapter.updateOne(
        {
          id: language.id,
          changes: language
        },
        {
          ...state,
          loading: false,
          error: null
        }
      )
    ),
    on(LanguagesActions.changePublishingStatusFailure, (state, { error }) => ({
      ...state,
      loading: false,
      error: error
    }))
  )
});
