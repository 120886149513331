import { Action, createReducer, on } from '@ngrx/store';
import { Account } from './account.model';
import * as AuthActions from './auth.actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  user: Account.Model | null;
  error: Error | null;
  loading: boolean;
}

export const initialState: AuthState = {
  loading: false,
  user: null,
  error: null
};

export const reducer = createReducer(
  initialState,

  on(AuthActions.login, (state) => ({ ...state, loading: true })),
  on(AuthActions.loginSuccess, (state, action) => ({
    ...state,
    loading: false,
    user: action.data
  })),
  on(AuthActions.loginFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.error
  })),
  on(AuthActions.activateAccountFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.error
  })),

  on(AuthActions.logoutConfirmed, (state) => ({ ...initialState })),

  on(AuthActions.setUserFromTokenSuccess, (state, action) => ({
    ...state,
    loading: false,
    user: action.data
  }))
);
