import { LanguagesEffects } from '@app/languages/store/languages.effects';
import {
  selectActiveLanguages,
  selectLanguages,
  selectLoading
} from '@app/languages/store/languages.selectors';

export const languageEffects = [LanguagesEffects];

export const languageSelectors = {
  selectLoading,
  selectLanguages,
  selectActiveLanguages
};

export * as languagePageActions from './languages.actions';

export { languageFeature, languageFeatureKey } from './languageFeature';
